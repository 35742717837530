import React from "react"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { StaticImage } from "gatsby-plugin-image"

class PortfolioSection extends React.Component {
  render() {
    return (
      <div className="pt-100">
      <section className="portfoliosec mb-0">
        <div className="gray-bg">
          <div className="container">
              <h4 className="heading-h2 text-center">
                {" "}
                <span className="heading-h3">
                  <span className="bluecolor">Our Portfolio Powers</span> more
                  than 350
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faPlus} />{" "}
                  </span>
                </span>
                Cryptocurrency Exchanges Globally{" "}
              </h4>
            <div className="quick text-center">
              <StaticImage src="https://coinsclone.mo.cloudinary.net/images/home-new/our-portfolio.webp" 
              alt="our portfolio"
              width={1110} />
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default PortfolioSection

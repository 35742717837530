import React from 'react'
import ButtonStartfree from '../ButtonComponent/ButtonStartfree'


class BannerSection extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="banner">
        <div className="container text-center">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
            <h1 className="main-heading">DeFi Wallet Development Company</h1>
            <p className="sub-heading">Experience an exceptional opportunity with the decentralized financial wallet.</p>
            <p className="sub-small">Customize your DeFi wallets that support a wide range of cryptos with complete branding.</p>
          </div>
          <div className="quick text-center">
            <img width="983px" height="579px" src="https://coinsclone.mo.cloudinary.net/images/defiwallet/defi-wallet-development-company.png" alt="DeFi wallet Development Company Banner" />
          </div>
          <p className="pharagraph">Join the DeFi revolution with a wallet that's uniquely yours!
          </p>
          <div className="text-center mt-4">
            <ButtonStartfree />
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection